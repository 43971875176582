<template>
  <div class="sgl-article">
    <transition name="default">
      <section class="content-section" v-if="contentLoaded">
        <div class="inner-container">
          <router-link to="/news" class="navigation-button">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15 19L8 12L15 5" stroke="#EB3333" stroke-width="1.5" stroke-linejoin="round"/>
            </svg>
            К новостям
          </router-link>
          <div class="sgl-article--wrapper">
            <div class="sgl-article--content">
              <div class="article-head">
                <!--              <div class="sgl-label sgl-label&#45;&#45;colored">Образование</div>-->
                <h1 class="article-title" v-if="this.contentLoaded">{{ page.attributes.title }}</h1>
                <div class="article-sources">
                  <div class="date">{{ pageCreationDate }}</div>
                  <div class="source">
                    <span>Источник:</span>
                    <router-link to="/" v-if="SPONSOR">{{ SPONSOR.attributes.name }}</router-link>
                    <div v-if="this.page.relation" class="slash" v-html="'&nbsp/&nbsp'"></div>
                    <router-link v-if="this.page.relation"
                                 :to="{name: getRelationType, params: {id: page.relation.id}}">
                      {{ page.relation.attributes.name }}
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="article-body" v-html="page.attributes.content">
              </div>
              <div class="article-foot">
                <div class="title-row">
                  <div class="title">Комментарии <span v-if="commentsArr.length">{{ commentsArr.length }}</span>
                  </div>
                  <ul class="text-tabs-list">
                    <!--у элементов есть класс active, для подчеркивания-->
                    <li @click="changeSortOrder(el);" v-for="el in this.newsSort" :key="el.order"
                        :class="{active: currentSortOrder.order === el.order}">{{ el.title }}
                    </li>
                  </ul>
                </div>
                <div class="textarea-zone" :class="{error: textAreaError}" v-if="USER.id">
                  <div class="error-text">Минимальная длинна - 1 символ</div>
                  <textarea v-model="currentComment" placeholder="Написать комментарий..."></textarea>
                  <div class="post-button"
                       :class="{'post-button__waiting': commentButtonInProgress, 'post-button__disabled': commentButtonInDisabled}"
                       @click="sendComment()">
                    <div class="button-content">
                      <div class="call">Отправить</div>
                      <div class="wait"><img src="~@/img/ico_spinner.png" alt=""></div>
                    </div>
                  </div>
                </div>
                <template v-if="commentsLoaded">
                  <div class="comment-section" v-if="commentsArr.length">
                    <news-single-commentary v-for="comment in commentsArr" :key="comment.id" :commentary="comment"/>
                  </div>
                </template>
                <template v-else>
                  <div class="comment-section">
                    <skelet-rectangle v-for="s in this.commentsArr" :key="s.id" :width="99999" :height="90"/>
                  </div>
                </template>
              </div>
              <div class="sgl-article--another-news">
                <div class="section-title">Другие новости</div>
                <div class="another-news__container">
                  <news-card with-date v-for="singleNews in this.latestNews.slice(0,3)" :key="singleNews.id"
                             :single-news="singleNews"/>
                </div>
              </div>
            </div>
            <aside class="sgl-article--aside">
              <!--            <a href="/" class="sgl-article&#45;&#45;banner">
                            <img :src="require('@/img/sidebar.png')" alt="#">
                          </a>
                          <div class="sgl-article&#45;&#45;shares">
                            <div class="title">Поделиться в соцсетях:</div>
                            <ul>
                              <li>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                                      fill="white"/>
                                  <path
                                      d="M22.9397 8.18495L7.11949 14.4172C7.11949 14.4172 6.40039 14.6569 6.40039 15.1363C6.40039 15.6157 7.11949 15.8554 7.11949 15.8554L11.0745 17.1737C11.0745 17.1737 11.7936 21.2486 12.5127 22.3273C12.7524 22.567 12.9922 22.6868 12.9922 22.6868C13.2319 22.8067 13.4716 22.6868 13.4716 22.6868L16.1083 20.2898L20.1832 23.4059C21.2618 23.8853 21.6214 22.9265 21.6214 22.9265L24.2581 8.5445C24.2581 7.5857 22.9397 8.18495 22.9397 8.18495ZM21.0221 11.4209C20.6626 11.6606 13.4716 18.1325 13.4716 18.1325V18.2524L13.3517 19.4509L12.9922 21.9677C12.9922 21.9677 12.8723 21.9677 12.5127 21.3685C12.1532 20.5295 11.6738 16.934 11.6738 16.934L20.6626 11.0614C20.6626 11.0614 21.2618 10.7018 21.142 11.0614C21.142 11.0614 21.2618 11.0614 21.0221 11.4209Z"
                                      fill="#272727"/>
                                </svg>
                              </li>
                              <li>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                                      fill="white"/>
                                  <path
                                      d="M18.0964 11.1454C17.8567 11.1454 17.4971 11.505 17.4971 12.1042V14.0218H20.8529V16.7784H17.4971V25.1679H14.2612V16.7784H11.3848V14.0218H14.2612V12.3439C14.2612 9.9469 15.9391 8.0293 18.2162 8.0293H20.9728V11.0256H18.0964V11.1454Z"
                                      fill="#272727"/>
                                </svg>
                              </li>
                              <li>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                                      fill="white"/>
                                  <path
                                      d="M21.4525 16.1789C20.9731 15.5797 20.254 15.2201 19.2952 14.9804C19.8945 14.7407 20.3739 14.2613 20.7334 13.7819C21.093 13.1827 21.2128 12.5834 21.2128 11.8643C21.2128 11.2651 21.093 10.6658 20.8533 10.1864C20.7334 9.70702 20.254 9.34747 19.7746 9.10777C19.2952 8.86807 18.8158 8.62837 18.2166 8.50852C17.6173 8.50852 16.8982 8.38867 15.8196 8.38867H10.666V23.0104H16.5387C17.4975 23.0104 18.3364 22.8905 19.0555 22.7707C19.6548 22.531 20.254 22.2913 20.8533 21.8119C21.3327 21.4523 21.6922 20.9729 21.9319 20.4935C22.1716 19.8943 22.2915 19.295 22.2915 18.5759C22.2915 17.6171 22.0518 16.7782 21.4525 16.1789ZM17.3776 13.4224C17.3776 13.6621 17.1379 13.7819 16.7784 14.0216C16.5387 14.1415 16.299 14.1415 16.0593 14.1415C15.8196 14.1415 15.3402 14.1415 14.7409 14.1415H14.2615V11.1452H14.5012C15.1005 11.1452 15.6997 11.1452 15.9394 11.1452C16.299 11.1452 16.5387 11.2651 16.7784 11.2651C17.0181 11.3849 17.2578 11.5048 17.3776 11.7445C17.4975 12.104 17.6173 12.3437 17.6173 12.5834C17.6173 12.8231 17.4975 13.1827 17.3776 13.4224ZM18.3364 19.4149C18.2166 19.6546 17.9769 19.8943 17.6173 20.0141C17.2578 20.134 16.8982 20.2538 16.5387 20.2538C16.1791 20.2538 15.46 20.2538 14.6211 20.2538H14.2615V16.6583H14.9806C15.5799 16.6583 16.1791 16.6583 16.5387 16.6583C16.8982 16.6583 17.2578 16.6583 17.4975 16.7782C17.857 16.898 18.0967 17.1377 18.3364 17.3774C18.5761 17.6171 18.5761 17.9767 18.5761 18.4561C18.5761 18.8156 18.4563 19.1752 18.3364 19.4149Z"
                                      fill="#272727"/>
                                </svg>
                              </li>
                              <li>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                                      fill="white"/>
                                  <path
                                      d="M23.2515 11.7459C23.2515 11.8657 23.2515 12.1054 23.2515 12.2253C23.2515 17.259 19.4163 22.892 12.5848 22.892C10.4275 22.892 8.50993 22.2927 6.83203 21.2141C7.07173 21.2141 7.43128 21.2141 7.79083 21.2141C9.58859 21.2141 11.1466 20.6148 12.465 19.656C10.7871 19.656 9.46874 18.5774 8.98933 17.0193C9.22904 17.1392 9.46874 17.1392 9.70844 17.1392C10.068 17.1392 10.4275 17.1392 10.6672 17.0193C8.98933 16.6598 7.67098 15.1017 7.67098 13.3039C8.15038 13.5436 8.74963 13.7833 9.34889 13.7833C8.39008 13.0642 7.67098 11.9856 7.67098 10.6672C7.67098 9.94814 7.91068 9.34889 8.15038 8.74964C10.068 11.0268 12.8245 12.5848 15.9406 12.7047C15.8208 12.465 15.8208 12.1054 15.8208 11.8657C15.8208 9.82829 17.4987 8.15039 19.656 8.15039C20.7347 8.15039 21.6935 8.62979 22.4126 9.34889C23.2515 9.22904 24.0905 8.86949 24.8096 8.50994C24.5699 9.34889 23.9706 10.068 23.1317 10.5474C23.8508 10.4275 24.5699 10.1878 25.289 9.94814C24.6897 10.5474 23.9706 11.1466 23.2515 11.7459Z"
                                      fill="#272727"/>
                                </svg>
                              </li>
                              <li>
                                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                      d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                                      fill="white"/>
                                  <path
                                      d="M20.5135 17.446L22.7662 15.1928C24.4096 13.5498 24.4096 10.8757 22.7662 9.23269C21.123 7.5891 18.4491 7.5891 16.8059 9.23269L13.5548 12.4838C11.9114 14.1268 11.9114 16.8009 13.5548 18.4439C13.7975 18.6867 14.063 18.8926 14.3441 19.0636L16.0845 17.323C15.753 17.2437 15.4386 17.0764 15.1804 16.8185C14.4335 16.0715 14.4335 14.856 15.1804 14.1092L18.4315 10.8581C19.1785 10.1112 20.394 10.1112 21.1408 10.8581C21.8877 11.6051 21.8877 12.8204 21.1408 13.5674L20.1857 14.5227C20.5792 15.449 20.688 16.4678 20.5135 17.446Z"
                                      fill="#272727"/>
                                  <path
                                      d="M11.4855 14.5532L9.23269 16.8061C7.5891 18.4493 7.5891 21.1232 9.23269 22.7665C10.8757 24.4099 13.5498 24.4099 15.193 22.7665L18.4439 19.5154C20.0873 17.8722 20.0872 15.1983 18.4439 13.5552C18.2015 13.3124 17.9359 13.1066 17.6548 12.9355L15.9144 14.6762C16.2457 14.7556 16.5602 14.9226 16.8185 15.1807C17.5654 15.9276 17.5654 17.143 16.8185 17.8899L13.5672 21.141C12.8203 21.888 11.6049 21.888 10.858 21.141C10.111 20.3941 10.111 19.1787 10.858 18.4318L11.8132 17.4766C11.4195 16.5503 11.3107 15.5314 11.4855 14.5532Z"
                                      fill="#272727"/>
                                </svg>
                              </li>
                            </ul>
                          </div>-->
            </aside>
          </div>
        </div>
      </section>
    </transition>
    <transition name="default">
      <section class="content-section" v-if="!contentLoaded">
        <div class="inner-container">
          <skelet-rectangle class="rect-button" :height="24" :width="200"/>
          <skelet-rectangle class="rect-title" :height="38" :width="450"/>
          <skelet-rectangle class="rect-subtitle" :height="14" :width="450"/>
          <skelet-rectangle v-for="s in 10" :key="s.id" class="rect-content" :height="18" :width="680"/>
        </div>
      </section>
    </transition>
    <scroll-top/>
  </div>
</template>

<script>
import ScrollTop from "@/components/UIElements/scrollTop";
import {mapActions, mapGetters} from 'vuex';
import moment from "moment";
import {SEO_KEYWORDS, SEO_TITLE} from "@/config";
import NewsSingleCommentary from "@/components/News/news-single-commentary";
import NewsCard from "@/components/News/news-card";
import SkeletRectangle from "@/views/sceleton/components/rectangle";

export default {
  name: "news-page",
  components: {SkeletRectangle, NewsCard, NewsSingleCommentary, ScrollTop},
  data() {
    return {
      currentComment: '',
      contentLoaded: false,
      page: {},
      commentsArr: [],
      latestNews: [],
      newsSort: [
        {order: 'ascending', title: 'Новые'},
        {order: 'descending', title: 'Старые'}
      ],
      currentSortOrder: {order: 'ascending', title: 'Новые'},
      commentButtonInProgress: false,
      commentButtonInDisabled: false,
      textAreaError: false,
      commentsLoaded: false,
    }
  },
  computed: {
    ...mapGetters([
      'SPONSOR',
      'USER'
    ]),
    getRelationType() {
      return this.page?.relation?.type === "groups-of-tournaments" ? 'sgl-events' : 'tournaments_id'
    },
    pageCreationDate() {
      moment.locale('ru')
      let date = moment(this.page.attributes.createdAt).valueOf()
      return moment(date).format(" HH:mm, Do MMM YYYY")
    }
  },
  methods: {
    ...mapActions([
      'FETCH_SINGLE_NEWS',
      'FETCH_LATEST_NEWS',
      'POST_COMMENT'
    ]),
    setPageToData(payload) {
      this.page = payload
    },
    setNewsToData(payload) {
      this.commentsArr = payload.comments
    },
    setLatestNewsToData(payload) {
      this.latestNews = payload
    },
    changeSortOrder(el) {
      if (this.currentSortOrder.order === el.order) return
      this.currentSortOrder = {order: el.order, title: el.title}

      this.currentSortOrder.order === 'ascending'
          ? this.commentsArr.sort((a, b) => new Date(b.attributes.createdAt).valueOf() - new Date(a.attributes.createdAt))
          : this.commentsArr.sort((a, b) => new Date(a.attributes.createdAt).valueOf() - new Date(b.attributes.createdAt))
    },
    async getLatestNews() {
      this.setLatestNewsToData(await this.FETCH_LATEST_NEWS({page_number: 1, page_size: 3}))
    },
    async sendComment() {
      if (this.currentComment.length) {
        let dataToPost = {
          news_id: this.page.id,
          user_id: this.USER.id,
          message: this.currentComment
        }
        try {
          if (this.commentButtonInProgress === false && this.commentButtonInDisabled !== true) {
            this.commentButtonInProgress = true
            await this.POST_COMMENT(dataToPost)
            this.currentComment = ''
            this.commentButtonInProgress = false
            await this.refreshNewsArr()
          }
        } catch (error) {
          console.dir(error)
        }
      } else {
        this.textAreaError = true
        setTimeout(() => {
          this.textAreaError = false
        }, 2000)
      }
    },
    async refreshNewsArr() {
      this.commentsLoaded = false
      this.setNewsToData(await this.FETCH_SINGLE_NEWS(this.$router.currentRoute.params.id))
      this.commentsLoaded = true
    }
  },
  async mounted() {
    this.contentLoaded = false
    this.commentsLoaded = false
    this.setPageToData(await this.FETCH_SINGLE_NEWS(this.$router.currentRoute.params.id))
    await this.getLatestNews()
    this.contentLoaded = true
    this.commentsLoaded = true

    document.title = `${this.page.attributes.title ?? SEO_TITLE}`
    document.querySelector('meta[name="description"]')?.setAttribute("content", `${this.page.attributes.seo_description ?? SEO_KEYWORDS}`);
    this.commentsArr = this.page.comments
  },
  beforeDestroy() {
    document.title = SEO_TITLE
    document.querySelector('meta[name="description"]')?.setAttribute("content", `${SEO_KEYWORDS}`);
  }
}
</script>

<style scoped>
</style>
