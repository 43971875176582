<template>
  <div class="post-component">
    <div class="post-component__head">
      <router-link :to="{name: 'another-profile', params: {id: commentary.author.id}}" target="_blank"
                   class="post-author">
        <div class="ava">
          <img :src="commentary.author.avatar" alt="#">
          <div class="dot"></div>
        </div>
        <div class="info">
          <div class="name">{{
              commentary.author.attributes.login
            }}<span>{{ commentary.author.attributes.firstName + ' ' + commentary.author.attributes.lastName }}</span>
          </div>
          <!--          <div class="exp">2 380 exp</div>-->
        </div>
      </router-link>
      <div class="date">{{ getPostDate }}</div>
    </div>
    <div class="post-component__body">
      <div class="post-context">{{ commentary.attributes.message }}</div>
      <!--      <div v-if="!isReply" class="operations">
              <div v-if="withReplies" :class="['reveal-dialog',{active: commentsRevealed}]">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4 6L8 10L12 6" stroke="#B0B0B0" stroke-linejoin="round"/>
                </svg>
                Показать диалог
              </div>
              <div class="options">
                <span>Цитировать</span>
                <span>Ответить</span>
              </div>
            </div>
            <div ref="replies" class="replies" v-if="withReplies">
              <div class="divider"></div>
              <post-component isReply/>
              <post-component isReply/>
            </div>
            -->
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "news-single-commentary",
  data() {
    return {};
  },
  props: {
    commentary: Object
  },
  computed: {
    getPostDate() {
      moment.locale('ru')
      let date = moment(this.commentary.attributes.createdAt).valueOf()
      return moment(date).format("D MMMM YYYY, HH:mm")
    }
  },
  methods: {},
  mounted() {
  }
}
</script>

<style scoped>

</style>
